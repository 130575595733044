import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class AboutService {
  // url = "/assets/json/about.json";
  // url = "http://127.0.0.1:9000/site/api-about";
   url="/site/api-about";

  about: any;
  isLoaded = false;


  getAbout(): Promise < any > {

    if (this.isLoaded) {
      return new Promise < any > (function(resolve, reject) {
        resolve();
      });
    }


    return this.http.get(this.url)
      .toPromise()
      .then(response => {
        this.isLoaded = true;
        this.about = response;
        return response;

      }).catch();
  }


  constructor(private http: HttpClient) {

  }


}
