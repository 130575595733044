import {Component, OnInit, AfterViewInit, OnDestroy} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {WorkService} from '../../service/work.service';
import {Work} from '../work.model';

declare const jQuery: any;
declare const videojs: any;
@Component({
  selector: 'app-work-detail',
  templateUrl: './work-detail.component.html',
  styleUrls: ['./work-detail.component.less']
})

export class WorkDetailComponent implements OnInit, AfterViewInit, OnDestroy {

  id: string;
  showVideoLink: boolean;
  isUpdateVideo: boolean;
  private videoJSplayer;
  private videoJSplayer2;
  work = new Work();
  type = "represent";

  constructor(private router: Router, private route: ActivatedRoute, public workService: WorkService) {
    route.params.subscribe(params => {
      this.id = params['id'];
      this.showVideoLink = false;
      this.isUpdateVideo = false;
      console.log('component detail')
      this.workService.getWorks().then(data => {
        const idx = this.workService.works.findIndex(obj => obj.id === this.id);

        if (idx !== -1) {
          this.work = this.workService.works[idx];
          this.type = this.work.type;
          this.work.prevIdx = -1;
          this.work.nextIdx = -1;
          if (idx !== 0) {
            this.work.prevIdx = idx - 1;
          }
          if (idx !== this.workService.works.length - 1) {
            this.work.nextIdx = idx + 1;
          }
          if (this.work.vedioLink.length > 10) {
            this.showVideoLink = true;
          }
          if (this.work.vedioSource.length > 10) {
            this.isUpdateVideo = true;
          }
          setTimeout(() => {
            if (this.videoJSplayer == null) {
              this.videoJSplayer = videojs(document.getElementById('my-video'), {language: 'zh-CN'}, function () {
              });
            } else {
              this.videoJSplayer.poster(this.work.vedioPost);
              this.videoJSplayer.src({ src: this.work.vedioSource});
              // this.videoJSplayer.src({type: this.work.vedioType, src: this.work.vedioSource});
            }
            if (this.videoJSplayer2 == null) {
              this.videoJSplayer2 = videojs(document.getElementById('my-video2'), {language: 'zh-CN'}, function () {
              });
            } else {
              this.videoJSplayer2.poster(this.work.vedioPost);
              this.videoJSplayer2.src({ src: this.work.vedioSource});
            }
          });
        }
      });
    });
  }

  open(url: string): void {
    window.open(url);
  }

  videoPlay() {
    if(this.isUpdateVideo){
      if(this.videoJSplayer.played()){
         this.videoJSplayer.pause();
      }
      this.videoJSplayer2.play();
      jQuery("#videoDialog").modal("show");
      jQuery("#videoDialog").on('hide.bs.modal', function () {
        videojs(document.getElementById('my-video2')).pause();
      });
    }
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    // this.videoJSplayer = videojs(document.getElementById('my-video'), {}, function () {
    // });
  }

  ngOnDestroy() {
    console.log("ddodo");
    try {
      this.videoJSplayer.dispose();
      this.videoJSplayer2.dispose();
    } catch (e) {

    }
  }

  prev() {
    if (this.work.prevIdx !== -1) {
      this.router.navigate(['work', '' + this.workService.works[this.work.prevIdx].id]);
    }
  }

  next() {
    if (this.work.nextIdx !== -1) {
      //  console.log(this.work.nextIdx);
      this.router.navigate(['work', '' + this.workService.works[this.work.nextIdx].id]);
    }
  }


}
