import {Component, HostListener, OnInit, AfterViewInit, OnDestroy} from '@angular/core';
import {WorkService} from '../service/work.service';
import {HttpClient} from '@angular/common/http';

import {
  ActivatedRoute,
  Router
} from '@angular/router';

declare const videojs: any;

@Component({
  selector: 'app-work',
  templateUrl: './work.component.html',
  styleUrls: ['./work.component.less']
})
export class WorkComponent implements OnInit, AfterViewInit, OnDestroy {

  type = '';
  works = [];

  workSwiper: Swiper = null;
  oldWorkCount = 0;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.resetSwpier();
  }

  listChange(): void {
  }

  constructor(private router: Router, private activeRouter: ActivatedRoute, public workService: WorkService, private http: HttpClient) {
    activeRouter.queryParams.subscribe(params => {
      this.type = params['type'];
      console.log('p type is',params['type']);
      if (this.type === undefined) {
        this.type = 'represent';
      }

      this.workService.getWorks().then(data => {
        console.log('this type',this.type);
        this.workService.setType(this.type);
        setTimeout(() => {
          this.resetSwpier();
            });
          });
        }
    );
  }

  goToNav(paht1: string, path2: string): void {
    this.router.navigate([paht1, path2]);
  }

  resetSwpier(): void {

    let _goToNav = this.goToNav;

    if (this.workSwiper != null) {
      try {
        this.workSwiper.destroy(false);
      } catch (e) {
      }
    }
    let width: number = window.innerWidth;
    if (width < 576) {
      this.workSwiper = new Swiper('.swiper-container', {
        slidesPerView: 2,
        spaceBetween: 10,
        slidesPerGroup: 1,

        // loop: true,
        // loopFillGroupWithBlank: false,
        centeredSlides: true,
        navigation: {
          nextEl: '.carousel-control-next',
          prevEl: '.carousel-control-prev',
        },
        on: {
          click: function () {
            // _goToNav("work","11");
          }
        }
      });
    } else {
      let height:number=window.innerHeight;
      let slidesPerView=4;
      if(1.0*width/height<=(14.0/9)){
        slidesPerView=3;
      }

      this.workSwiper = new Swiper('.swiper-container', {
        slidesPerView: slidesPerView,
        spaceBetween: 0,
        slidesPerGroup: slidesPerView,
        // loop: true,
        // loopFillGroupWithBlank: false,
        on: {
          click: function () {
            // _goToNav("work","11");
          }
        },
        navigation: {
          nextEl: '.carousel-control-next',
          prevEl: '.carousel-control-prev',
        }
      });
    }
  }

  ngAfterViewInit() {

  }

  // ngDoCheck(): void {
  //   // if (this.oldWorkCount != this.workService.works.length) {
  //   //   console.log("loadWork");
  //   //   this.oldWorkCount = this.workService.works.length;
  //   //   setTimeout(() => {
  //   //     this.resetSwpier();
  //   //   });
  //   // }
  // }

  ngOnInit() {
    // this.workService.request();
  }

  ngOnDestroy() {
    if (this.workSwiper != null) {
      try {
        this.workSwiper.destroy(false);
      } catch (e) {
      }
    }
  }
}
