import {Component, HostListener, OnInit, AfterViewInit} from '@angular/core';
import {NavigationEnd} from '@angular/router';
import {fadeAnimation} from './animations/fadeAnimation';

import {
  ActivatedRoute,
  Router
} from '@angular/router';
import {Location} from '@angular/common';
import {WorkService} from "./service/work.service";
import {NewsService} from "./service/news.service";
import {AboutService} from "./service/about.service";

declare const Parallax: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  animations: [fadeAnimation]
})

export class AppComponent implements OnInit, AfterViewInit {

  routerState = true;
  routerStateCode = 'active';

  constructor(private router: Router, private location: Location, private workService: WorkService, private  newsService: NewsService, private aboutService: AboutService) {
    // this.router.events.subscribe(event => {
    //   if (event instanceof NavigationEnd) {
    //     // 每次路由跳转改变状态
    //     this.routerState = !this.routerState;
    //     this.routerStateCode = this.routerState ? 'active' : 'inactive';
    //   }
    // });
  }


  goToNav(paht1: string, path2: string): void {
    this.router.navigate([paht1, path2]);
  }


  @HostListener('window:resize', ['$event'])
  onResize(event) {
    //  resizeHandel(window.innerWidth, window.innerHeight);
  }


  curTime = 0;      //本次的getTime()
  oldTime = 0;      //上一次的getTime()
  whellFlag = 5;   //设定一个判断变量，保证在条件下console.log只执行一次


  @HostListener('mousewheel', ['$event'])
  wheel(event) {
    this.oldTime = this.curTime;
    this.curTime = new Date().getTime();


    const nav_strs = ["home", "work/list", "news/list", "about"];
    let delta = (event.wheelDelta || event.detail );
    let pathStr = this.location.path().toString();
    if (delta > 0) {
      delta = -1;
    } else {
      delta = 1;
    }
    let idx: number = -9;
    for (let i in nav_strs) {

      if (pathStr.endsWith(nav_strs[i])) {
        idx = Number(i);
        break;
      }

    }
    idx += delta;

    //连续滚动
    if ((this.curTime - this.oldTime) <= 300) {
      console.log("ddododo");
      if (delta != this.whellFlag) {
        this.whellFlag = delta;
        if (0 <= idx && idx <= 3) {
          console.log(nav_strs[idx]);
          this.router.navigate([nav_strs[idx]]);
        }
      }
    } else {
      if (0 <= idx && idx <= 3) {
        console.log(nav_strs[idx]);
        this.router.navigate([nav_strs[idx]]);
      }
      this.whellFlag = delta;
    }



  }

  ngOnInit() {
    this.aboutService.getAbout().then(data => {
    });
    this.newsService.getNews().then(data => {
    });
    this.workService.getWorks().then(data => {
    });
  }


  ngAfterViewInit() {
    //  initBackgroundCanvas();
    //  resizeHandel(window.innerWidth, window.innerHeight);


    var graphScene = document.getElementById('movegraphs');
    new Parallax(graphScene);

  }


  isActive(str: string): boolean {
    return (this.location.path().toString().indexOf(str) >= 0);
  }

  isDetail(str: string): boolean {
    let path = this.location.path().toString();

    if (path.indexOf(str) < 0) {
      return false;
    }
    let li = path.lastIndexOf("/");
    if (li > 0) {
      path = path.substring(li + 1);
      path = path.replace(/\?.*/,'');
      const reg = /^[0-9]+$/;
      if (reg.test(path)) {
        return true;
      } else {
        return false;
      }
    }

    return false;

  }

  title = 'colorfulpan';
}
