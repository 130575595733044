import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';


@Injectable()
export class NewsService {
  // url = "/assets/json/news.json";
  // url = "http://127.0.0.1:9000/site/api-news";
   url = "/site/api-news";
  news = [];
  notice = [];
  hr = [];
  maker = [];
  join = [];
  latest = [];
  cur = [];

  isLoaded = false;


  setType(type: string) {
    if (type === "notice") {
      this.cur = this.notice;
    } else if (type === "hr") {
      this.cur = this.hr;
    } else if (type === "maker") {
      this.cur = this.maker;
    } else if (type === "join") {
      this.cur = this.join;
    } else if (type === "latest") {
      this.cur = this.latest;
    } else {
      this.cur = this.news;
    }
  }

  getNews(): Promise<any> {
    if (this.isLoaded) {
      return new Promise<any>(function (resolve, reject) {
        resolve();
      });
    }

    return this.http.get(this.url)
      .toPromise()
      .then(response => {
        if (Array.isArray(response)) {
          this.isLoaded = true;
          this.news = response;
          this.notice = [];
          this.hr = [];
          this.maker = [];
          this.cur = [];
          this.latest = [];
          this.join = [];
          for (let entry of response) {
            if (entry.type === "notice") {
              this.notice.push(entry);
            } else if (entry.type === "hr") {
              this.hr.push(entry);
            } else if (entry.type === "maker" || entry.type === "marker") {
              this.maker.push(entry);
            }else if (entry.type === "latest") {
              this.latest.push(entry);
            }else if (entry.type === "join") {
              this.join.push(entry);
            }
          }
          return response;
        }
        return [];
      }).catch();
  }

  constructor(private http: HttpClient) {

  }


}
