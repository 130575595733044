import {Component, OnInit, HostListener, AfterViewInit, OnDestroy} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {NewsService} from "../service/news.service";

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.less']
})
export class NewsComponent implements OnInit, AfterViewInit, OnDestroy {

  workSwiper: Swiper = null;
  type = "all";


  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.resetSwpier();
  }


  constructor(public activeRouter: ActivatedRoute, public  newsService: NewsService) {
    activeRouter.queryParams.subscribe(params => {
      this.type = params['type'];
      if (this.type == undefined) {
        this.type = "all";
      }
      this.newsService.getNews().then(data => {
        this.newsService.setType(this.type);
        setTimeout(() => {
          this.resetSwpier();
        });
      });
    });
  }

  resetSwpier(): void {
    if (this.workSwiper != null) {
      try {
        this.workSwiper.destroy(false);
      } catch (e) {
      }
    }
    let width: number = window.innerWidth;
    if (width < 576) {
      this.workSwiper = new Swiper('.swiper-container', {
        slidesPerView: 'auto',
        centeredSlides: true,
        spaceBetween: 10,
        pagination: {
          clickable: true,
        },
      });
    } else {
      let height:number=window.innerHeight;
      let slidesPerView=3;
      if(1.0*width/height<=(15.0/9)){
        slidesPerView=2;
      }
      this.workSwiper = new Swiper('.swiper-container', {
        slidesPerView: slidesPerView,
        spaceBetween: 0,
        slidesPerGroup: slidesPerView,
        // loop: true,
        // loopFillGroupWithBlank: false,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        navigation: {
          nextEl: '.carousel-control-next',
          prevEl: '.carousel-control-prev',
        },
      });
    }
  }

  ngAfterViewInit() {

  }

  ngOnInit() {
    console.log();
  }

  ngOnDestroy() {
    if (this.workSwiper != null) {
      try {
        this.workSwiper.destroy(false);
      } catch (e) {
      }
    }
  }
}
