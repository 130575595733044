import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Work} from '../work/work.model';


@Injectable()
export class WorkService {
  // url = "http://127.0.0.1:9000/site/api-videos";
   url = "/site/api-videos";
 // url = "/assets/json/works.json";
  works = [];
  loadedWorks = [];
  representWorks = [];
  cur=[];
  partakeWorks = [];
  isLoaded = false;
  test = 1;

  setTest() {
    this.test++;
  }

  setWorks(works) {
    this.works = works;
  }

  handle(data) {
    console.log(data);
    if (Array.isArray(data)) {
      this.isLoaded = true;
      this.works = data;
    }
  }

  getWorks(): Promise<any> {

    if (this.isLoaded) {
      return new Promise<any>(function (resolve, reject) {
        resolve();
      });
    }

    return this.http.get(this.url)
      .toPromise()
      .then(response => {
        if (Array.isArray(response)) {
          console.log('resp',response);
          this.isLoaded = true;
          this.works = response;

          for (let entry of response) {
              if(this.loadedWorks.indexOf(entry.id) >= 0){
                  continue;
              }
              this.loadedWorks.push(entry.id);
            if (entry.type === 'represent') {
              this.representWorks.push(entry);
            } else {
              this.partakeWorks.push(entry);
            }
          }
          return response;
        }
        return [];
      }).catch();
  }


  setType(type: string) {
    if (type === "represent") {
      this.cur = this.representWorks;
    }  else {
      this.cur = this.partakeWorks;
    }
  }

  request() {
    if (this.isLoaded) {
      return this.works;
    } else {
      this.http
        .get(this.url)
        .subscribe(data => {
          this.handle(data);
        });
    }
  }

  constructor(private http: HttpClient) {

  }


}
