/**
 * Created by Administrator on 2018/8/26 0026.
 */
export class Work {
  id: string;
  name: string;
  englishName: string;
  imgList: string;
  imgSmall: string;
  desc: string;
  vedioPost: string;
  vedioSource: string;
  vedioLink: string;
  vedioType: string;
  type: string;

  prevIdx: number = -1;
  nextIdx: number = -1;

  constructor() {
    this.name = '';
    this.englishName = '';
  }

}
