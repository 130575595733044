import {Component, OnInit, AfterViewInit, OnDestroy} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {NewsService} from "../../service/news.service";

@Component({
  selector: 'app-news-detail',
  templateUrl: './news-detail.component.html',
  styleUrls: ['./news-detail.component.less']
})
export class NewsDetailComponent implements OnInit {
  type = "all";
  id: string;
  news: any;

  constructor(private router: Router, private route: ActivatedRoute, public  newsService: NewsService) {
    route.params.subscribe(params => {
      this.id = params['id'];
    });
    route.queryParams.subscribe(params => {
      this.type = params['type'];
      if (this.type == undefined) {
        this.type = "all";
      }
      this.newsService.getNews().then(data => {
        this.news = this.newsService.news.find(obj => obj.id === this.id);
      });
    });
  }

  ngOnInit() {
  }


}
