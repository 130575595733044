import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {WorkService} from './service/work.service';
import {HttpClientModule} from '@angular/common/http';

import {
  RouterModule,
  ActivatedRoute,
  Router,
  Routes
} from '@angular/router';
import {AppComponent} from './app.component';
import {HomeComponent} from './home/home.component';
import {AboutComponent} from './about/about.component';
import {WorkComponent} from './work/work.component';
import {NewsComponent} from './news/news.component';
import {WorkDetailComponent} from './work/work-detail/work-detail.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NewsDetailComponent} from './news/news-detail/news-detail.component';
import {AboutService} from "./service/about.service";
import {NewsService} from "./service/news.service";
import { ShowALlPipePipe } from './pipe/show-all-pipe.pipe';

const routes: Routes = [
  // basic routes
  {path: '', redirectTo: 'home', pathMatch: 'full'},
  {path: 'home', component: HomeComponent},
  {path: 'news/list', component: NewsComponent},
  {path: 'work/list', component: WorkComponent},
  {path: 'about', component: AboutComponent},
  {path: 'news/:id', component: NewsDetailComponent},
  {path: 'work/:id', component: WorkDetailComponent}
];


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AboutComponent,
    WorkComponent,
    NewsComponent,
    WorkDetailComponent,
    NewsDetailComponent,
    ShowALlPipePipe
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(routes, { useHash: true }), // <-- routes
    BrowserAnimationsModule,
    HttpClientModule
  ],
  providers: [WorkService, AboutService, NewsService],
  bootstrap: [AppComponent]
})
export class AppModule {
}
