import {Component, OnInit} from '@angular/core';
import {AboutService} from '../service/about.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.less']
})
export class AboutComponent implements OnInit {

  about: any;

  constructor(public aboutService: AboutService) {
    this.aboutService.getAbout().then(data => {
    });
  }

  ngOnInit() {

  }

}
